<template>
   <main>
      <!-- breadcrumb area start -->
      <section class="breadcrumb__area pt-30 grey-bg">
         <div class="container">
            <div class="breadcrumb__wrapper">
               <nav aria-label="breadcrumb">
                  <ol class="breadcrumb mb-0">
                     <li class="breadcrumb-item"><router-link to="/"><i class="far fa-home color-theme me-2"></i>Home</router-link></li>
                     <li class="breadcrumb-item active" aria-current="page">História</li>
                  </ol>
               </nav>
            </div>
         </div>
      </section>

      <!-- about start -->
      <section class="about__area pt-40 pb-200 grey-bg">
         <div class="container">
            <div class="row align-items-center">
               <div class="col-xl-6">
                  <div class="w-img pb-5 pb-xxl-0">
                     <img class="rounded" src="@/assets/cliente/img/banner/empresa-04.jpg" />
                  </div>
               </div>
               <div class="col-xl-6 px-xxl-5 text-justify">
                  <h3 class="text-center mb-30" v-html="sourceHistoria.titulo"></h3>
                  <p v-html="sourceHistoria.descricao"></p>
               </div>
            </div>
         </div>
      </section>
   </main>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'História',
   computed: {
		... mapState({
			sourceHistoria: state => state.source.historia
		})
	}
}

</script>